import { PureComponent } from 'react';
import { Select } from 'shared/components/Select';
import { getString } from 'shared/components/StringLabel';
import { FormElementReactSelect } from '../types';

interface Props<T> {
  element: FormElementReactSelect<T>;
  disabled?: boolean;
  value: any;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
  onGetEntity: () => T | Partial<T>;
}

interface AsyncState<T = any> {
  isLoading?: boolean;
  values?: T[];
}

interface State {
  asyncResults: { [stateId: string]: AsyncState };
}

export class FormReactSelect<T> extends PureComponent<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);
    this.state = { asyncResults: {} };
  }

  render() {
    const { element, disabled } = this.props;
    const value = element.onValue
      ? element.onValue(this.props.value, 'get')
      : this.props.value;
    const entity = this.props.onGetEntity();
    const extra = this.props.onGetExtraInfo?.();
    const valueProp = element.valueProp || 'value';

    const isOptionDisabled = element.isOptionDisabled
      ? this.isOptionDisabled
      : undefined;

    let isLoading = false;

    let values =
      (typeof element.values === 'function'
        ? element.values({ entity, value, extra })
        : element.values) || [];
    const stateId =
      typeof element.stateId === 'function'
        ? element.stateId(entity)
        : element.stateId;
    if (element.async) {
      if (!stateId) {
        throw new Error('async select form element requires stateId property');
      }
      const asyncResult = this.state.asyncResults[stateId];
      isLoading = (asyncResult && asyncResult.isLoading) || false;
      values = asyncResult?.values || [];
    }

    const selectedValue = element.multi
      ? values.filter(x => value?.includes(x[valueProp]))
      : values.find(x => x[valueProp] === value);

    return (
      <Select
        key={stateId}
        values={values}
        width={element.width}
        valueProp={element.valueProp || 'value'}
        labelProp={element.labelProp || 'label'}
        multi={element.multi}
        closeMenuOnSelect={element.closeMenuOnSelect}
        disabled={disabled}
        selectedValue={selectedValue}
        placeholder={getString(element.placeholder)}
        isLoading={isLoading}
        isClearable={element.clearable}
        isSearchable={element.searchable ?? true}
        className="toolbar__select"
        isOptionDisabled={isOptionDisabled}
        onGetOptionValue={element.onGetOptionValue}
        onGetOptionLabel={element.onGetOptionLabel}
        onFormatOptionLabel={element.onFormatOptionLabel}
        onChange={this.onChange}
        noOptionsMessage={element.noOptionsMessage}
        async={element.async}
        defaultValues={element.defaultValues}
        onLoadValues={this.onLoadValues}
        cacheValues={element.cacheValues}
        creatable={element.creatable}
        allowCreateWhileLoading={element.allowCreateWhileLoading}
        onFormatCreateLabel={element.onFormatCreateLabel}
        isValidNewOption={element.isValidNewOption}
        onGetNewOptionData={element.onGetNewOptionData}
        onCreateOption={element.onCreateOption}
        createOptionPosition={element.createOptionPosition}
        createOnBlur={element.createOnBlur}
      />
    );
  }

  isOptionDisabled = (option: any) => {
    const { element } = this.props;
    const extra = this.props.onGetExtraInfo?.();
    return element.isOptionDisabled!(option, extra);
  };

  onChange = (option: any | any[] | undefined | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    if (element.multi) {
      changes[element.prop] = option
        ? option.map((x: any) => x[element.valueProp!])
        : [];
    } else {
      changes[element.prop] = option ? option[element.valueProp!] : undefined;
    }

    if (element.onValue) {
      changes[element.prop] = element.onValue(changes[element.prop], 'set');
    }

    void (element.onChange && element.onChange(changes, extra));
    this.props.onChange(changes);
  };

  onLoadValues = async (inputValue: string) => {
    const { element } = this.props;
    const entity = this.props.onGetEntity();
    const value = entity[element.prop];
    const stateId =
      typeof element.stateId === 'function'
        ? element.stateId(entity)
        : element.stateId;
    if (!stateId) {
      throw new Error('toolbar async select requires stateId property');
    }
    if (!element.onLoadValues) {
      throw new Error('toolbar async select requires onLoadValues property');
    }

    const asyncResult = this.state.asyncResults[stateId] || {
      isLoading: false,
      selected: null,
      values: [],
    };

    const updateAsyncState = (props: Partial<AsyncState>) => {
      this.setState({
        asyncResults: {
          ...this.state.asyncResults,
          [stateId!]: { ...asyncResult, ...props },
        },
      });
    };

    updateAsyncState({ isLoading: true });

    try {
      const values = await element.onLoadValues(inputValue, entity, value);
      updateAsyncState({ isLoading: false, values });
      return values;
    } catch (e) {
      console.error(e);
      updateAsyncState({ isLoading: false, values: [] });
    }

    return [];
  };
}
