import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import {
  kOrderFieldVisibilityOptions,
  kReportDefectiveLevelSortOrderOptions,
  kReportImageResizeModeOptions,
} from 'app/settings/constants';
import {
  ActiveExtendedProperty,
  ExtendedProperties,
} from 'app/settings/ExtendedProperties';
import { OrderFields } from 'app/settings/OrderFields';
import { produce } from 'immer';
import { tokenService } from 'lib';
import { Button } from 'lib/metronic/components';
import {
  AppBehaviorConfig,
  AppLaunchBrandingSourceType,
  AppLaunchConfig,
  ExtendedPropertyDef,
  ExtendedPropertyType,
  ImageAnnotationScene,
  ImageAnnotationSceneOptions,
  makeDefaultStoreConfiguration,
  Option,
  OrderCommentedNotificationTarget,
  OrderFieldsConfig,
  OrderFieldVisibility,
  OrgUserRoleType,
  OrgUserRoleTypeOptions,
  Store,
  StoreConfiguration,
} from 'model';
import { nanoid } from 'nanoid/non-secure';
import { pick } from 'ramda';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { organizationService, storeService } from 'services';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  getString,
} from 'shared/components';
import { ConfirmModal } from 'shared/components/ConfirmModal';
import { array_move } from 'utils';
import { usePersistFn } from 'utils/usePersistFn';
import { OrderTagsEditor } from './OrderTagsEditor';
import type { ExtendedOrderPropertyConfig } from './PropertyEditor';

export const kOrderCommentedNotificationTargets: OrderCommentedNotificationTarget[] =
  [
    OrderCommentedNotificationTarget.ServiceAgent,
    OrderCommentedNotificationTarget.Manager,
  ];

export const OrderCommentedNotificationTargetOptions: Array<
  Option<OrderCommentedNotificationTarget>
> = [
  {
    value: OrderCommentedNotificationTarget.ServiceAgent,
    label: 'store.config.order_commented_notification_target.service_agent',
  },
  {
    value: OrderCommentedNotificationTarget.Manager,
    label: 'store.config.order_commented_notification_target.manager',
  },
];

export const AppLaunchBrandingOptions: Array<
  Option<AppLaunchBrandingSourceType>
> = [
  {
    value: 'default',
    label: 'store.config.label.app_launch.branding.system_default',
  },
  {
    value: 'agent',
    label: 'store.config.label.app_launch.branding.agent',
  },
  {
    value: 'org',
    label: 'store.config.label.app_launch.branding.org',
  },
];

type ModifiedStoreConfiguration = Omit<
  StoreConfiguration,
  'commentRemind' | 'serviceAgreement' | 'appLaunch' | 'app'
> & {
  commentRemind_delayInHours?: number;
  commentRemind_intervalInHours?: number;
  commentRemind_maxRemind?: number;
  serviceAgreement?: string;
  appLaunch_animationDisabled?: boolean;
  appLaunch_branding?: AppLaunchBrandingSourceType;
  app_autoAnnotationTagForPreInspectionDisabled?: boolean;
  app_orderSummary_defaultExtendedPropsVisibility?: OrderFieldVisibility;
  app_orderSummary_vehicleFuelTypeVisibility?: OrderFieldVisibility;
  app_orderSummary_storeNameVisibility?: OrderFieldVisibility;
  app_orderFlow_fields?: OrderFieldsConfig;
  app_orderFlow_groups?: string[];
  app_orderFlow_skipSign?: boolean;
  app_orderTagsForNav?: string[];
  app_imageAnnotationDisabledForScenes?: ImageAnnotationScene[];
};

type PropertyFieldList = (keyof ExtendedOrderPropertyConfig)[];

const kCommonFields: PropertyFieldList = [
  'id',
  'type',
  'group',
  'name',
  'prop',
  'description',
  'placeholder',
  'visibility',
  'required',
  'autoFill',
  'includeInOrderSummary',
  'attachments',
  'color',
  'updatableBy',
];

const kSelectFields: PropertyFieldList = [
  ...kCommonFields,
  'options',
  'multi',
  'renderType',
  'remark',
];

const kTextFields: PropertyFieldList = [
  ...kCommonFields,
  'minLength',
  'maxLength',
  'multiline',
];

const kSwitchFields: PropertyFieldList = [
  ...kCommonFields,
  'remark',
  'defaultOn',
  'yesValue',
  'noValue',
  'yesLabel',
  'noLabel',
  'yesColor',
  'noColor',
];

const kDatetimeFields: PropertyFieldList = [
  ...kCommonFields,
  'dateOnly',
  'displayFormat',
  'remark',
];

const kAssetsFields: PropertyFieldList = [
  ...kCommonFields,
  'limit',
  'supportedFormats',
  'remark',
];

const kFieldsMap: { [p in ExtendedPropertyType]: PropertyFieldList } = {
  text: kTextFields,
  select: kSelectFields,
  switch: kSwitchFields,
  datetime: kDatetimeFields,
  assets: kAssetsFields,
};

const getExtendedOrderPropertyConfigObject = (
  property: ExtendedPropertyDef,
): ExtendedOrderPropertyConfig => {
  const fields = kFieldsMap[property.type];
  return pick(fields as any, property);
};

function storeConfigurationToModified(
  conf: StoreConfiguration,
): ModifiedStoreConfiguration {
  const {
    commentRemind,
    serviceAgreement,
    appLaunch,
    app,
    productReferralServiceAgentDistributionRatio,
    productAdoptionServiceAgentDistributionRatio,
    ...props
  } = conf;
  const saDistributionRatioConverted = (x: number | undefined) =>
    x == null ? x : x > 1 ? x : x * 100;
  return {
    ...props,
    commentRemind_delayInHours: commentRemind?.delayInHours,
    commentRemind_intervalInHours: commentRemind?.intervalInHours,
    commentRemind_maxRemind: commentRemind?.maxRemind,
    serviceAgreement: serviceAgreement?.content,
    appLaunch_animationDisabled: appLaunch?.animationDisabled,
    appLaunch_branding: appLaunch?.branding ?? 'default',
    app_autoAnnotationTagForPreInspectionDisabled:
      app?.autoAnnotationTagForPreInspectionDisabled,
    app_orderSummary_defaultExtendedPropsVisibility:
      app?.orderSummary?.defaultExtendedPropsVisibility,
    app_orderSummary_vehicleFuelTypeVisibility:
      app?.orderSummary?.fields?.vehicleFuelType?.visibility,
    app_orderSummary_storeNameVisibility:
      app?.orderSummary?.fields?.storeName?.visibility,
    app_orderFlow_groups: app?.orderFlow?.groups,
    app_orderFlow_fields: app?.orderFlow?.fields,
    app_orderFlow_skipSign: app?.orderFlow?.skipSign,
    app_orderTagsForNav: app?.orderTagsForNav,
    app_imageAnnotationDisabledForScenes: app?.imageAnnotationDisabledForScenes,
    productReferralServiceAgentDistributionRatio: saDistributionRatioConverted(
      productReferralServiceAgentDistributionRatio,
    ),
    productAdoptionServiceAgentDistributionRatio: saDistributionRatioConverted(
      productAdoptionServiceAgentDistributionRatio,
    ),
  };
}

function toNumber(s: number | string | null | undefined) {
  if (typeof s === 'number') return s;
  if (s == null) return undefined;
  s = s.trim();
  if (!s) return undefined;
  return Number(s);
}

function storeConfigurationFromModified(
  modified: ModifiedStoreConfiguration,
): StoreConfiguration {
  const {
    commentRemind_delayInHours,
    commentRemind_intervalInHours,
    commentRemind_maxRemind,
    appLaunch_animationDisabled,
    appLaunch_branding,
    serviceAgreement,
    app_autoAnnotationTagForPreInspectionDisabled,
    app_orderSummary_defaultExtendedPropsVisibility,
    app_orderSummary_vehicleFuelTypeVisibility,
    app_orderSummary_storeNameVisibility,
    app_orderFlow_groups,
    app_orderFlow_fields,
    app_orderFlow_skipSign,
    app_orderTagsForNav,
    app_imageAnnotationDisabledForScenes,
    productReferralServiceAgentDistributionRatio,
    productAdoptionServiceAgentDistributionRatio,
    ...props
  } = modified;
  const [delayInHours, intervalInHours, maxRemind] = [
    commentRemind_delayInHours,
    commentRemind_intervalInHours,
    commentRemind_maxRemind,
  ].map(toNumber);
  const commentRemind: StoreConfiguration['commentRemind'] =
    delayInHours != null || intervalInHours != null || maxRemind != null
      ? {
          delayInHours,
          intervalInHours,
          maxRemind,
        }
      : null;
  const appLaunch: AppLaunchConfig | null =
    (appLaunch_animationDisabled == null ||
      appLaunch_animationDisabled === false) &&
    (!appLaunch_branding || appLaunch_branding === 'default')
      ? null
      : {
          animationDisabled: appLaunch_animationDisabled,
          branding: appLaunch_branding,
        };
  const isOrderSummaryFieldsConfigEmpty =
    app_orderSummary_vehicleFuelTypeVisibility == null &&
    app_orderSummary_storeNameVisibility == null;
  const isOrderSummaryConfigEmpty =
    app_orderSummary_defaultExtendedPropsVisibility == null &&
    isOrderSummaryFieldsConfigEmpty;

  const isOrderFlowFieldsConfigEmpty =
    app_orderFlow_fields == null ||
    Object.keys(app_orderFlow_fields).length === 0 ||
    Object.keys(app_orderFlow_fields).every(
      x => (app_orderFlow_fields as any)[x] == null,
    );
  const isOrderFlowConfigEmpty =
    (app_orderFlow_groups == null || app_orderFlow_groups.length === 0) &&
    (app_orderFlow_skipSign == null || app_orderFlow_skipSign === false) &&
    isOrderFlowFieldsConfigEmpty;

  const app: AppBehaviorConfig | null =
    app_autoAnnotationTagForPreInspectionDisabled == null &&
    app_orderSummary_defaultExtendedPropsVisibility == null &&
    isOrderSummaryConfigEmpty &&
    isOrderFlowConfigEmpty &&
    !app_orderTagsForNav?.length &&
    !app_imageAnnotationDisabledForScenes?.length
      ? null
      : {
          autoAnnotationTagForPreInspectionDisabled:
            app_autoAnnotationTagForPreInspectionDisabled,
          orderSummary: isOrderSummaryConfigEmpty
            ? undefined
            : {
                defaultExtendedPropsVisibility:
                  app_orderSummary_defaultExtendedPropsVisibility,
                fields: isOrderSummaryFieldsConfigEmpty
                  ? undefined
                  : {
                      vehicleFuelType:
                        app_orderSummary_vehicleFuelTypeVisibility == null
                          ? undefined
                          : {
                              visibility:
                                app_orderSummary_vehicleFuelTypeVisibility,
                            },
                      storeName:
                        app_orderSummary_storeNameVisibility == null
                          ? undefined
                          : {
                              visibility: app_orderSummary_storeNameVisibility,
                            },
                    },
              },
          orderFlow: isOrderFlowConfigEmpty
            ? undefined
            : {
                groups: app_orderFlow_groups,
                fields: isOrderFlowFieldsConfigEmpty
                  ? undefined
                  : app_orderFlow_fields,
                skipSign: app_orderFlow_skipSign,
              },
          orderTagsForNav: app_orderTagsForNav,
          imageAnnotationDisabledForScenes:
            app_imageAnnotationDisabledForScenes,
        };

  return {
    ...props,
    commentRemind,
    appLaunch,
    app,
    productReferralServiceAgentDistributionRatio:
      productReferralServiceAgentDistributionRatio != null
        ? productReferralServiceAgentDistributionRatio / 100
        : undefined,
    productAdoptionServiceAgentDistributionRatio:
      productAdoptionServiceAgentDistributionRatio != null
        ? productAdoptionServiceAgentDistributionRatio / 100
        : undefined,
    serviceAgreement: serviceAgreement?.trim()
      ? {
          type: 'html',
          content: serviceAgreement.trim(),
        }
      : undefined,
  };
}

export const SettingsEditor = memo(
  ({ storeId }: { orgId: number; storeId?: number }) => {
    const dispatch = useDispatch();

    const isDirty = useRef(false);

    const [store, setStore] = useState<Store>();

    const [config, setConfig] = useState<ModifiedStoreConfiguration>(() =>
      storeConfigurationToModified(makeDefaultStoreConfiguration()),
    );

    const saveConfig = usePersistFn(
      async (modifiedConfig: ModifiedStoreConfiguration) => {
        dispatch(
          showAppLoading({
            message: getString('store.config.saving'),
          }),
        );
        try {
          const conf = storeConfigurationFromModified(modifiedConfig);
          if (storeId == null) {
            await organizationService.saveConfiguration(conf);
          } else {
            await storeService.saveConfiguration(storeId, conf);
          }
          dispatch(hideAppLoading());
          isDirty.current = false;
        } catch (e) {
          dispatch(
            showAppLoading({
              status: 'error',
              message: e.message,
            }),
          );
          throw e;
        }
      },
    );

    useEffect(() => {
      if (storeId == null) {
        setStore(undefined);
        return;
      }
      void (async () => {
        const res = await storeService.get(storeId);
        setStore(res ?? undefined);
      })();
    }, [storeId]);

    const onChange = usePersistFn(
      async (values: Partial<ModifiedStoreConfiguration>) => {
        const modified = { ...config, ...values };
        isDirty.current = true;
        await saveConfig(modified);
        setConfig(modified);
      },
    );

    const onServiceAgreementChange = usePersistFn((value: string) => {
      if (config.serviceAgreement === value) {
        return;
      }
      const modified: ModifiedStoreConfiguration = {
        ...config,
        serviceAgreement: value,
      };
      setConfig(modified);
      isDirty.current = true;
    });

    const onServiceAgreementBlur = usePersistFn(async () => {
      if (!isDirty.current) return;
      await saveConfig(config);
    });

    const [activeProperty, setActiveProperty] =
      useState<ActiveExtendedProperty>();

    const onRemoveExtendedProperty = usePersistFn(
      (property: ExtendedPropertyDef) => {
        if (
          confirm(getString('store.config.extended_property.confirm_remove'))
        ) {
          if (!config.extendedOrderProperties) {
            return;
          }
          const index = config.extendedOrderProperties.findIndex(
            x => x.id === property.id,
          );
          if (index >= 0) {
            const updated = produce(config.extendedOrderProperties, draft => {
              draft.splice(index, 1);
            });
            void onChange({
              extendedOrderProperties: updated,
            });
          }
        }
      },
    );

    const onAddExtendedProperty = useCallback(() => {
      setActiveProperty({
        type: 'new',
        property: {
          id: nanoid(6),
          name: '',
          type: 'select',
          multi: true,
          options: [],
        },
      });
    }, []);

    const onEditExtendedProperty = useCallback(
      (property: ExtendedPropertyDef) => {
        setActiveProperty({
          type: 'edit',
          property,
        });
      },
      [],
    );

    const onExtendedPropertyEditorClose = useCallback(() => {
      setActiveProperty(undefined);
    }, []);

    const onMoveExtendedProperty = usePersistFn(
      (property: ExtendedPropertyDef, dir: 'up' | 'down') => {
        if (config.extendedOrderProperties == null) return;
        const fromIndex = config.extendedOrderProperties.findIndex(
          x => x.id === property.id,
        );
        if (fromIndex < 0) return;
        let toIndex: number | undefined = undefined;
        if (dir === 'up' && fromIndex > 0) {
          toIndex = fromIndex - 1;
        } else if (
          dir === 'down' &&
          fromIndex < config.extendedOrderProperties?.length - 1
        ) {
          toIndex = fromIndex + 1;
        }
        if (toIndex == null) return;
        const properties = produce(config.extendedOrderProperties, draft => {
          array_move(draft, fromIndex, toIndex!);
        });
        void onChange({ extendedOrderProperties: properties });
      },
    );

    const onSaveOrderFields = usePersistFn(
      async (fields: OrderFieldsConfig) => {
        await onChange({
          app_orderFlow_fields: fields,
        });
      },
    );

    const onSaveExtendedProperty = usePersistFn(
      async (property: ExtendedPropertyDef) => {
        if (!activeProperty) return;
        property = {
          ...property,
          attachments: property.attachments?.filter(x => x.content),
        };
        const converted = getExtendedOrderPropertyConfigObject(property);
        property = converted as any;
        const properties = produce(
          config.extendedOrderProperties ?? [],
          draft => {
            if (activeProperty.type === 'new') {
              draft.push(property);
            } else {
              const index = draft.findIndex(x => x.id === property.id);
              if (index >= 0) {
                draft[index] = property;
              }
            }
          },
        );
        await onChange({ extendedOrderProperties: properties });
        setActiveProperty(undefined);
      },
    );

    const onAddTag = usePersistFn((name: string) => {
      if (config.orderTags?.some(x => x.name === name)) {
        alert(getString('store.config.error.order_tag_exists'));
        return;
      }
      void onChange({ orderTags: [...(config.orderTags ?? []), { name }] });
    });

    const onRemoveTag = usePersistFn((name: string) => {
      void onChange({
        orderTags: config.orderTags?.filter(x => x.name !== name),
      });
    });

    const onTagChecked = usePersistFn((name: string, checked: boolean) => {
      void onChange({
        orderTags: config.orderTags?.map(x =>
          x.name === name ? { name, disabled: checked ? undefined : true } : x,
        ),
      });
    });

    const onCommentRemindDelayInHoursChange = usePersistFn(
      (values: Partial<ModifiedStoreConfiguration>) => {
        const value = toNumber(values.commentRemind_delayInHours);
        if (value != null && (isNaN(value) || value < 0)) {
          alert(getString('store.config.order_comment_remind.delay_in_hours'));
          return false;
        }
        if (config.commentRemind_delayInHours != value) {
          const conf: ModifiedStoreConfiguration = {
            ...config,
            commentRemind_delayInHours: value,
          };
          void onChange(conf);
        }
        return false;
      },
    );

    const onCommentRemindIntervalInHoursChange = usePersistFn(
      (values: Partial<ModifiedStoreConfiguration>) => {
        const value = toNumber(values.commentRemind_intervalInHours);
        if (value != null && (isNaN(value) || value < 0)) {
          alert(
            getString('store.config.order_comment_remind.interval_in_hours'),
          );
          return false;
        }
        if (config.commentRemind_intervalInHours != value) {
          const conf: ModifiedStoreConfiguration = {
            ...config,
            commentRemind_intervalInHours: value,
          };
          void onChange(conf);
        }
        return false;
      },
    );

    const onCommentRemindMaxRemindChange = usePersistFn(
      (values: Partial<ModifiedStoreConfiguration>) => {
        const value = toNumber(values.commentRemind_maxRemind);
        if (
          value != null &&
          (isNaN(value) || value < 0 || (value | 0) !== value)
        ) {
          alert(getString('store.config.order_comment_remind.max_remind'));
          return false;
        }
        if (config.commentRemind_maxRemind != value) {
          const conf: ModifiedStoreConfiguration = {
            ...config,
            commentRemind_maxRemind: value,
          };
          void onChange(conf);
        }
        return false;
      },
    );

    const form = useMemo(() => {
      const builder = new EntityEditorFormBuilder<ModifiedStoreConfiguration>();
      builder
        .custom({
          key: 'extended_properties',
          unwrapLabel: true,
          label: (
            <div className="form-group__label">
              <Translate id="store.config.label.extended_property.editor_label" />
              <Button
                color="brand"
                size="small"
                outline
                outlineHover
                onClick={() => {
                  onAddExtendedProperty();
                }}
                style={{
                  color: '#5867dd',
                  marginLeft: '1rem',
                  padding: '0.05rem 0.25rem',
                  textDecoration: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <i className="la la-plus" style={{ fontSize: '1rem' }} />
                <Translate id="store.config.button.add_extended_property" />
              </Button>
              <Button
                color="brand"
                size="small"
                outline
                outlineHover
                onClick={e => {
                  e.preventDefault();
                  const data = JSON.stringify(
                    config.extendedOrderProperties ?? [],
                    null,
                    2,
                  );
                  const file = new Blob([data], { type: 'application/json' });
                  const url = URL.createObjectURL(file);

                  const link = document.createElement('a');
                  link.download = `extended-properties.json`;
                  link.href = url;
                  document.body.appendChild(link);
                  link.click();

                  setTimeout(() => {
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                  }, 0);
                }}
                style={{
                  color: '#5867dd',
                  marginLeft: '0.75rem',
                  padding: '0.05rem 0.25rem',
                  textDecoration: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <i
                  className="la la-copy"
                  style={{ marginRight: '0.15rem', fontSize: '1rem' }}
                />
                <Translate id="store.config.button.export" />
              </Button>
              <Button
                color="brand"
                size="small"
                outline
                outlineHover
                file
                accepts={['application/json']}
                onFileChange={e => {
                  if (!e.target.files || e.target.files.length !== 1) {
                    return;
                  }
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.addEventListener('load', async () => {
                    const properties = JSON.parse(
                      reader.result as string,
                    ) as ExtendedPropertyDef[];
                    if (properties.length === 0) {
                      return;
                    }
                    if (
                      properties.some(property => {
                        if (
                          !property.name ||
                          (property.type !== 'text' &&
                            property.type !== 'select' &&
                            property.type !== 'switch' &&
                            property.type !== 'assets' &&
                            property.type !== 'datetime')
                        ) {
                          return true;
                        }
                        return false;
                      })
                    ) {
                      alert('Invalid file');
                      return;
                    }

                    console.log('properties: ', properties);

                    if (
                      confirm(
                        getString(
                          'store.config.extended_property.import_confirm',
                        ),
                      )
                    ) {
                      await onChange({ extendedOrderProperties: properties });
                    }
                  });
                  reader.readAsText(file);
                }}
                style={{
                  color: '#5867dd',
                  marginLeft: '0.75rem',
                  padding: '0.05rem 0.25rem',
                  textDecoration: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <i
                  className="la la-upload"
                  style={{ marginRight: '0.05rem', fontSize: '1rem' }}
                />
                <Translate id="store.config.button.import" />
              </Button>
            </div>
          ),
          render: (_, entity) => {
            return (
              <ExtendedProperties
                properties={entity.extendedOrderProperties ?? []}
                activeProperty={activeProperty}
                onEdit={onEditExtendedProperty}
                onEditorClose={onExtendedPropertyEditorClose}
                onRemove={onRemoveExtendedProperty}
                onSave={onSaveExtendedProperty}
                onMove={onMoveExtendedProperty}
              />
            );
          },
        })
        .custom({
          key: 'order_tags',
          label: 'store.config.label.order_tags',
          helpText: 'store.config.help_text.order_tags',
          className: 'form-group__order-tags',
          render: () => (
            <OrderTagsEditor
              tags={config.orderTags ?? undefined}
              onAdd={onAddTag}
              onRemove={onRemoveTag}
              onCheckChange={onTagChecked}
            />
          ),
        })
        .group(group => {
          group
            .withClassName('product_settings_group')
            .withKey('product_settings')
            .withLabel('store.config.product.title')
            .text({
              controlled: false,
              type: 'number',
              prop: 'productReferralServiceAgentDistributionRatio',
              label:
                'store.config.product.label.referral_sa_distribution_ratio',
              placeholder:
                'store.config.product.placeholder.referral_sa_distribution_ratio',
              style: {
                width: 250,
              },
              validate: (value: number) => {
                if (value < 0 || value > 100) {
                  return getString(
                    'store.config.product.error.invalid_distribution_ratio',
                  );
                }
                return true;
              },
            })
            .text({
              type: 'number',
              controlled: false,
              prop: 'productAdoptionServiceAgentDistributionRatio',
              label:
                'store.config.product.label.adoption_sa_distribution_ratio',
              placeholder:
                'store.config.product.placeholder.adoption_sa_distribution_ratio',
              style: {
                width: 250,
              },
              validate: (value: number) => {
                if (value < 0 || value > 100) {
                  return getString(
                    'store.config.product.error.invalid_distribution_ratio',
                  );
                }
                return true;
              },
            });
        })
        .checkbox({
          prop: 'includeCustomerInfoInExportedOrderDetailData',
          label:
            'store.config.label.include_customer_info_in_exported_order_detail_data',
          helpText: (
            <Translate
              id="store.config.help_text.include_customer_info_in_exported_order_detail_data.text"
              data={{
                terms_of_use: (
                  <a
                    href="https://zhichetech.com/terms-of-use.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Translate id="store.config.help_text.include_customer_info_in_exported_order_detail_data.terms_of_use" />
                  </a>
                ),
                privacy_policy: (
                  <a
                    href="https://zhichetech.com/privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Translate id="store.config.help_text.include_customer_info_in_exported_order_detail_data.privacy_policy" />
                  </a>
                ),
              }}
            />
          ),
        })
        .group(group => {
          group
            .withClassName('app-settings-group')
            .withKey('app_settings')
            .withLabel('store.config.label.app_settings.title')
            .multiTextInput({
              prop: 'app_orderTagsForNav',
              label: 'store.config.label.app_settings.order_tags_for_nav',
              placeholder:
                'store.config.placeholder.app_settings.order_tags_for_nav',
              serialize: x => x,
              deserialize: x => x,
              style: {
                alignSelf: 'stretch',
                marginBottom: '1rem',
                width: '50%',
              },
            })
            .reactSelect({
              prop: 'app_imageAnnotationDisabledForScenes',
              label:
                'store.config.label.app_settings.image_annotation_disabled_for_scenes',
              values: ImageAnnotationSceneOptions,
              valueProp: 'value',
              labelProp: 'label',
              multi: true,
              style: {
                alignSelf: 'stretch',
                marginBottom: '1rem',
                width: '50%',
              },
              onFormatOptionLabel(option: Option<any>) {
                return getString(option.label);
              },
            })
            .checkbox({
              prop: 'app_autoAnnotationTagForPreInspectionDisabled',
              label:
                'store.config.label.app_settings.auto_annotation_for_pre_inspection_disabled',
            })
            .group(g =>
              g
                .withKey('visibility_settings')
                .withClassName('order-summary-fields-visibility-settings')
                .withLabel(
                  'store.config.order_summary.label.visibility_settings',
                )
                .select({
                  className: 'app-settings-group__hbox',
                  prop: 'app_orderSummary_defaultExtendedPropsVisibility',
                  label:
                    'store.config.order_summary.label.fields.extended_props',
                  options: kOrderFieldVisibilityOptions,
                })
                .select({
                  className: 'app-settings-group__hbox',
                  prop: 'app_orderSummary_vehicleFuelTypeVisibility',
                  label:
                    'store.config.order_summary.label.fields.vehicle_fuel_type',
                  options: kOrderFieldVisibilityOptions,
                })
                .select({
                  className: 'app-settings-group__hbox',
                  prop: 'app_orderSummary_storeNameVisibility',
                  label: 'store.config.order_summary.label.fields.store_name',
                  options: kOrderFieldVisibilityOptions,
                }),
            );
        });
      builder.group(group => {
        group
          .withClassName('app_settings_order_flow')
          .withKey('app_settings_order_flow')
          .withLabel('store.config.order_flow.title')
          .withOrientation('vertical')
          .custom({
            key: 'app_settings_order_flow',
            label: '',
            render: (_, entity) => {
              return (
                <OrderFields
                  fields={entity.app_orderFlow_fields}
                  orderTags={config.orderTags?.map(x => x.name)}
                  onSave={onSaveOrderFields}
                />
              );
            },
          })
          .checkbox({
            prop: 'app_orderFlow_skipSign',
            label: 'store.config.order_flow.label.skip_sign',
          });
      });
      builder
        .html({
          prop: 'serviceAgreement',
          label: 'store.config.label.service_agreement',
          helpText: 'store.config.help_text.service_agreement',
          placeholder: 'store.config.placeholder.service_agreement',
          helpTextPlacement: 'after',
          onBlur: onServiceAgreementBlur,
          onChange(changes) {
            onServiceAgreementChange((changes.serviceAgreement as any) ?? '');
            return true;
          },
        })
        .checkbox({
          prop: 'printTermsOfServiceInNewPage',
          label: 'store.config.label.print_service_agreement_on_new_page',
        })
        .checkbox({
          prop: 'disableStoreQrCodeScanEventHandler',
          label: 'store.config.label.disable_store_qrcode_scan_event_handler',
        })
        .select({
          prop: 'customerReportImageResizeMode',
          label: 'store.config.label.customer_report_image_resize_mode',
          options: kReportImageResizeModeOptions,
          style: { maxWidth: '50%' },
        })
        .select({
          prop: 'customerReportDefectiveLevelSortOrder',
          label:
            'store.config.label.customer_report_defective_level_sort_order',
          options: kReportDefectiveLevelSortOrderOptions,
          style: { maxWidth: '50%' },
        })
        .group(g => {
          g.withKey('order_comment_remind')
            .withLabel('store.config.label.order_comment_remind.title')
            .text({
              controlled: false,
              prop: 'commentRemind_delayInHours',
              type: 'number',
              label: 'store.config.label.order_comment_remind.delay_in_hours',
              placeholder:
                'store.config.placeholder.order_comment_remind.delay_in_hours',
              onChange: onCommentRemindDelayInHoursChange,
            })
            .text({
              controlled: false,
              prop: 'commentRemind_intervalInHours',
              type: 'number',
              label:
                'store.config.label.order_comment_remind.interval_in_hours',
              placeholder:
                'store.config.placeholder.order_comment_remind.interval_in_hours',
              onChange: onCommentRemindIntervalInHoursChange,
            })
            .text({
              controlled: false,
              prop: 'commentRemind_maxRemind',
              type: 'number',
              label: 'store.config.label.order_comment_remind.max_remind',
              placeholder:
                'store.config.placeholder.order_comment_remind.max_remind',
              onChange: onCommentRemindMaxRemindChange,
            });
        })
        .checkboxList({
          prop: 'orderCommentedNotificationTargetsExcluded',
          label: 'store.config.label.order_commented_notification_targets',
          helpText:
            'store.config.help_text.order_commented_notification_targets',
          options: OrderCommentedNotificationTargetOptions,
          isValueChecked(optionValue, currentValue) {
            const target =
              optionValue as unknown as OrderCommentedNotificationTarget;
            const targets = new Set(
              (currentValue as unknown as
                | OrderCommentedNotificationTarget[]
                | undefined) ?? [],
            );
            return !targets.has(target);
          },
          getCheckValue(originalValue, value, checked) {
            const targets = new Set(
              (originalValue as unknown as
                | OrderCommentedNotificationTarget[]
                | undefined) ?? [],
            );
            const role = value as unknown as OrderCommentedNotificationTarget;
            if (checked) {
              targets.delete(role);
            } else {
              targets.add(role);
            }
            return [...targets];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForCreateOrder',
          label: 'store.config.label.restricted_roles_for_create_order',
          helpText: 'store.config.help_text.restricted_roles_for_create_order',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForUpdateOrder',
          label: 'store.config.label.restricted_roles_for_update_order',
          helpText: 'store.config.help_text.restricted_roles_for_update_order',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForFinishOrder',
          label: 'store.config.label.restricted_roles_for_finish_order',
          helpText: 'store.config.help_text.restricted_roles_for_finish_order',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForCancelOrder',
          label: 'store.config.label.restricted_roles_for_cancel_order',
          helpText: 'store.config.help_text.restricted_roles_for_cancel_order',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForRollbackOrder',
          label: 'store.config.label.restricted_roles_for_rollback_order',
          helpText:
            'store.config.help_text.restricted_roles_for_rollback_order',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'rolesForRollbackOrderWithoutRestrictions',
          label:
            'store.config.label.roles_for_rollback_order_without_restrictions',
          helpText:
            'store.config.help_text.roles_for_rollback_order_without_restrictions',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForPreDeliveryCheck',
          label: 'store.config.label.restricted_roles_for_pre_delivery_check',
          helpText:
            'store.config.help_text.restricted_roles_for_pre_delivery_check',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForDeliveryCheck',
          label: 'store.config.label.restricted_roles_for_delivery_check',
          helpText:
            'store.config.help_text.restricted_roles_for_delivery_check',
          className: 'form-group__restricted-roles-for-delivery-check',
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForPreSamplingDeliveryCheck',
          label:
            'store.config.label.restricted_roles_for_pre_sampling_delivery_check',
          helpText:
            'store.config.help_text.restricted_roles_for_pre_sampling_delivery_check',
          className: 'form-group__restricted-roles-for-delivery-check',
          hidden: storeId == null || !store?.isSamplingDeliveryCheckEnabled,
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .checkboxList({
          prop: 'restrictedRolesForSamplingDeliveryCheck',
          label:
            'store.config.label.restricted_roles_for_sampling_delivery_check',
          helpText:
            'store.config.help_text.restricted_roles_for_sampling_delivery_check',
          className: 'form-group__restricted-roles-for-delivery-check',
          hidden: storeId == null || !store?.isSamplingDeliveryCheckEnabled,
          options: OrgUserRoleTypeOptions,
          isValueChecked(optionValue, currentValue) {
            const role = optionValue as unknown as OrgUserRoleType;
            const roles = new Set(
              (currentValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            return roles.has(role);
          },
          getCheckValue(originalValue, value, checked) {
            const roles = new Set(
              (originalValue as unknown as OrgUserRoleType[] | undefined) ?? [],
            );
            const role = value as unknown as OrgUserRoleType;
            if (checked) {
              roles.add(role);
            } else {
              roles.delete(role);
            }
            return [...roles];
          },
        })
        .switch({
          prop: 'requireReasonForUpdateOrderEstimatedFinishTime',
          label:
            'store.config.label.require_reason_for_update_order_estimated_finish_time',
          helpText:
            'store.config.help_text.require_reason_for_update_order_estimated_finish_time',
        })
        .multiTextInput({
          prop: 'predefinedUpdateOrderEstimatedFinishTimeReasons',
          label:
            'store.config.label.predefined_reasons_for_update_order_estimated_finish_time',
          placeholder: 'store.config.placeholder.generic_predefined_reason',
          helpText:
            'store.config.help_text.predefined_reasons_for_update_order_estimated_finish_time',
          serialize: x => x,
          deserialize: x => x,
        })
        .switch({
          prop: 'checkOrderTimeoutBeforeFinish',
          label: 'store.config.label.check_order_timeout_before_finish',
          helpText: 'store.config.help_text.check_order_timeout_before_finish',
        })
        .multiTextInput({
          prop: 'predefinedOrderTimeoutReasons',
          label: 'store.config.label.predefined_reasons_for_order_timeout',
          placeholder: 'store.config.placeholder.generic_predefined_reason',
          helpText:
            'store.config.help_text.predefined_reasons_for_order_timeout',
          serialize: x => x,
          deserialize: x => x,
        })
        .switch({
          prop: 'requireDeliveryCheckUnacceptedReason',
          label: 'store.config.label.require_delivery_check_unaccepted_reason',
          helpText:
            'store.config.help_text.require_delivery_check_unaccepted_reason',
        })
        .multiTextInput({
          prop: 'predefinedDeliveryCheckUnacceptedReasons',
          label:
            'store.config.label.predefined_delivery_check_unaccepted_reasons',
          placeholder: 'store.config.placeholder.generic_predefined_reason',
          helpText:
            'store.config.help_text.predefined_delivery_check_unaccepted_reasons',
          serialize: x => x,
          deserialize: x => x,
        })
        .switch({
          prop: 'isDeliveryCheckOptional',
          label: 'store.config.label.is_delivery_check_optional',
          helpText: 'store.config.help_text.is_delivery_check_optional',
        })
        .switch({
          prop: 'isChildOrderDeliveryCheckOptional',
          label: 'store.config.label.is_child_order_delivery_check_optional',
          helpText:
            'store.config.help_text.is_child_order_delivery_check_optional',
        })
        .switch({
          prop: 'requireSamplingCheckBeforeFinish',
          label: 'store.config.label.require_sampling_check_before_finish',
          helpText:
            'store.config.help_text.require_sampling_check_before_finish',
          hidden: storeId == null || !store?.isSamplingDeliveryCheckEnabled,
        })
        .switch({
          prop: 'requireSamplingCheckForChildOrderBeforeFinish',
          label:
            'store.config.label.require_sampling_check_for_child_order_before_finish',
          helpText:
            'store.config.help_text.require_sampling_check_for_child_order_before_finish',
          hidden: storeId == null || !store?.isSamplingDeliveryCheckEnabled,
        })
        .switch({
          prop: 'allowSamplingCheckAfterFinish',
          label: 'store.config.label.allow_sampling_check_after_finish',
          helpText: 'store.config.help_text.allow_sampling_check_after_finish',
          hidden: storeId == null || !store?.isSamplingDeliveryCheckEnabled,
        });

      if (storeId == null) {
        builder.group(group => {
          group
            .withKey('reply_on_wx_external_subscribe')
            .withLabel('store.config.no_reply_on_wx_external_subscribe.title')
            .checkbox({
              prop: 'noReplyOnWxExternalSubscribe',
              label: 'store.config.label.no_reply_on_wx_external_subscribe',
              helpText:
                'store.config.help_text.no_reply_on_wx_external_subscribe',
            });
        });
      }

      const isAdmin =
        tokenService.decodeTokenPayload(tokenService.getToken())
          .authenticator === 'otc';

      if (isAdmin) {
        builder.group(group => {
          group
            .withClassName('app_launch_setting_group')
            .withKey('app_launch')
            .withLabel('store.config.label.app_launch.title')
            .checkbox({
              prop: 'appLaunch_animationDisabled',
              label: 'store.config.label.app_launch.animation_disabled',
            })
            .select({
              prop: 'appLaunch_branding',
              label: 'store.config.label.app_launch.branding.label',
              className: 'app_launch_branding_field',
              options: AppLaunchBrandingOptions,
              valueProp: 'value',
              labelProp: 'label',
            });
        });
      }

      return builder.build();
    }, [
      storeId,
      store,
      onServiceAgreementBlur,
      onAddExtendedProperty,
      config.extendedOrderProperties,
      config.orderTags,
      onChange,
      activeProperty,
      onEditExtendedProperty,
      onExtendedPropertyEditorClose,
      onRemoveExtendedProperty,
      onSaveExtendedProperty,
      onMoveExtendedProperty,
      onAddTag,
      onRemoveTag,
      onTagChecked,
      onSaveOrderFields,
      onServiceAgreementChange,
      onCommentRemindDelayInHoursChange,
      onCommentRemindIntervalInHoursChange,
      onCommentRemindMaxRemindChange,
    ]);

    const loadConfig = useCallback(async () => {
      if (storeId == null) {
        organizationService
          .getConfiguration()
          .then(res => {
            setConfig(storeConfigurationToModified(res));
          })
          .catch(err => {
            alert(err.message);
          });
      } else {
        storeService
          .getConfiguration(storeId, true)
          .then(res => {
            setConfig(storeConfigurationToModified(res));
          })
          .catch(err => {
            alert(err.message);
          });
      }
    }, [storeId]);

    useEffect(() => {
      void loadConfig();
    }, [loadConfig]);

    const [showDeleteConfigModal, setShowDeleteConfigModal] = useState(false);
    const [isDeletingConfig, setIsDeletingConfig] = useState(false);
    const [deleteConfigError, setDeleteConfigError] = useState<Error>();

    const onDeleteConfig = useCallback(() => {
      setShowDeleteConfigModal(true);
    }, []);

    const onConfirmDeleteConfig = useCallback(async () => {
      setIsDeletingConfig(true);
      try {
        if (storeId == null) {
          await organizationService.deleteConfiguration();
        } else {
          await storeService.deleteConfiguration(storeId);
        }
        setIsDeletingConfig(false);
        setShowDeleteConfigModal(false);
        dispatch(
          showAppLoading({
            status: 'success',
            timeout: 2000,
            message: getString('store.config.delete.success'),
          }),
        );
        void loadConfig();
      } catch (e) {
        setIsDeletingConfig(false);
        setDeleteConfigError(e);
      }
    }, [dispatch, loadConfig, storeId]);

    return (
      <>
        <div className="settings-editor">
          <form className="entity-editor-form m-form">
            <div className="m-portlet__body">
              <div className="m-form__section m-form__section--first">
                <EntityEditorForm
                  entity={config}
                  onChange={onChange}
                  elements={form.elements}
                  autocomplete={form.autocomplete}
                  useUncontrolled={form.useUncontrolled}
                  helpTextPlacement="before"
                />
                <Button color="danger" onClick={onDeleteConfig}>
                  <Translate id="store.config.delete.button" />
                </Button>
              </div>
            </div>
          </form>
        </div>
        <ConfirmModal
          isOpen={showDeleteConfigModal}
          i18nPrefix="store.config.delete.modal"
          loading={isDeletingConfig}
          error={deleteConfigError}
          onConfirm={onConfirmDeleteConfig}
          onCancel={() => setShowDeleteConfigModal(false)}
        />
      </>
    );
  },
);
