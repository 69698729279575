/**
 * @file: TemplateMessageSceneType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TemplateMessageSceneType {
  ServiceStarted = 'service_started',
  PreInspectionFinished = 'pre_inspection_finished',
  InspectionFinished = 'inspection_finished',
  ConstructionFinished = 'construction_finished',
  DeliveryCheckFinished = 'delivery_check_finished',
  ServiceUpdated = 'service_updated',
  ServiceFinished = 'service_finished',
  ServiceCommentRemind = 'service_comment_remind',
  VehicleBindVerify = 'vehicle_bind_verify',
  StoreStatsRemind = 'store_stats_remind',
  CustomerPostedComment = 'customer_posted_comment',
  MaintenancePlanRemind = 'maintenance_plan_remind',
  CustomerMaintenancePlanRemind = 'customer_maintenance_plan_remind',
}

export const TemplateMessageSceneTypeValueSet = new Set([
  TemplateMessageSceneType.ServiceStarted,
  TemplateMessageSceneType.PreInspectionFinished,
  TemplateMessageSceneType.InspectionFinished,
  TemplateMessageSceneType.ConstructionFinished,
  TemplateMessageSceneType.DeliveryCheckFinished,
  TemplateMessageSceneType.ServiceUpdated,
  TemplateMessageSceneType.ServiceFinished,
  TemplateMessageSceneType.ServiceCommentRemind,
  TemplateMessageSceneType.VehicleBindVerify,
  TemplateMessageSceneType.StoreStatsRemind,
  TemplateMessageSceneType.CustomerPostedComment,
  TemplateMessageSceneType.MaintenancePlanRemind,
  TemplateMessageSceneType.CustomerMaintenancePlanRemind,
]);
