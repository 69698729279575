import { OrderFieldVisibility } from 'model';

type Option<T = string> = { label: string; value: T };

export const kOrderFieldVisibilityOptions: Option<OrderFieldVisibility>[] = [
  {
    label: 'store.config.order_flow.values.field_display_in_summary.default',
    value: 'default',
  },
  {
    label: 'store.config.order_flow.values.field_display_in_summary.always',
    value: 'always',
  },
  {
    label: 'store.config.order_flow.values.field_display_in_summary.auto',
    value: 'auto',
  },
  {
    label: 'store.config.order_flow.values.field_display_in_summary.none',
    value: 'none',
  },
];

export const kReportImageResizeModeOptions: Option[] = [
  {
    label: 'report_image_resize_mode.cover',
    value: 'cover',
  },
  {
    label: 'report_image_resize_mode.contain',
    value: 'contain',
  },
];

export const kReportDefectiveLevelSortOrderOptions: Option[] = [
  {
    label: 'report_defective_level_sort_order.desc',
    value: 'desc',
  },
  {
    label: 'report_defective_level_sort_order.asc',
    value: 'asc',
  },
];
