import { CustomerMaintenancePlanRemindTemplatePropertyProvider } from 'app/integration/templates/properrty-providers/customer-maintenance-plan-remind';
import { MaintenancePlanRemindTemplatePropertyProvider } from 'app/integration/templates/properrty-providers/maintenance-plan-remind';
import { TemplateMessageSceneType } from 'model';
import { ConstructionFinishedTemplatePropertyProvider } from './construction-finished';
import { CustomerPostedCommentTemplatePropertyProvider } from './customer-posted-comment';
import { DeliveryCheckFinishedTemplatePropertyProvider } from './delivery-check-finished';
import { InspectionFinishedTemplatePropertyProvider } from './inspection-finished';
import { PreInspectionFinishedTemplatePropertyProvider } from './pre-inspection-finished';
import { ServiceCommentRemindTemplatePropertyProvider } from './service-comment-remind';
import { ServiceFinishedTemplatePropertyProvider } from './service-finished';
import { ServiceStartedTemplatePropertyProvider } from './service-started';
import { ServiceUpdatedTemplatePropertyProvider } from './service-updated';
import { StoreStatsRemindTemplatePropertyProvider } from './store-stats-remind';
import { TemplatePropertyProvider } from './types';
import { VehicleBindVerifyTemplatePropertyProvider } from './vehicle-bind-verify';

export * from './types';

const templatePropertyProvidersMap = new Map<
  TemplateMessageSceneType,
  TemplatePropertyProvider
>([
  [
    TemplateMessageSceneType.ServiceStarted,
    new ServiceStartedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.PreInspectionFinished,
    new PreInspectionFinishedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.InspectionFinished,
    new InspectionFinishedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.ConstructionFinished,
    new ConstructionFinishedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.DeliveryCheckFinished,
    new DeliveryCheckFinishedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.ServiceCommentRemind,
    new ServiceCommentRemindTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.CustomerPostedComment,
    new CustomerPostedCommentTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.ServiceUpdated,
    new ServiceUpdatedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.ServiceFinished,
    new ServiceFinishedTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.VehicleBindVerify,
    new VehicleBindVerifyTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.StoreStatsRemind,
    new StoreStatsRemindTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.MaintenancePlanRemind,
    new MaintenancePlanRemindTemplatePropertyProvider(),
  ],
  [
    TemplateMessageSceneType.CustomerMaintenancePlanRemind,
    new CustomerMaintenancePlanRemindTemplatePropertyProvider(),
  ],
]);

export function getTemplatePropertyProvider(
  scene: TemplateMessageSceneType,
): TemplatePropertyProvider {
  return templatePropertyProvidersMap.get(scene)!;
}

export function getTemplateScenes(): TemplateMessageSceneType[] {
  return [
    TemplateMessageSceneType.ServiceStarted,
    TemplateMessageSceneType.PreInspectionFinished,
    TemplateMessageSceneType.InspectionFinished,
    TemplateMessageSceneType.ConstructionFinished,
    TemplateMessageSceneType.DeliveryCheckFinished,
    TemplateMessageSceneType.ServiceCommentRemind,
    TemplateMessageSceneType.CustomerPostedComment,
    TemplateMessageSceneType.ServiceUpdated,
    TemplateMessageSceneType.ServiceFinished,
    TemplateMessageSceneType.VehicleBindVerify,
    TemplateMessageSceneType.StoreStatsRemind,
    TemplateMessageSceneType.MaintenancePlanRemind,
    TemplateMessageSceneType.CustomerMaintenancePlanRemind,
  ];
}
