/**
 * @file: store-stats-remind copy.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
import {
  BaseTemplatePropertyProvider,
  TemplateMsgDestType,
  TemplateProperty,
} from './types';

export class CustomerMaintenancePlanRemindTemplatePropertyProvider extends BaseTemplatePropertyProvider {
  get supportedDestTypes(): TemplateMsgDestType[] {
    return ['customer'];
  }

  getOverriddenProperties(): TemplateProperty[] {
    return [
      'serviceSubject',
      'nextServiceDate',
      'serviceAgentName',
      'serviceAgentMobile',
    ].map(x => ({
      property: x,
      label: `integration.templates.props.maintenance_plan_remind.${x}`,
    }));
  }

  getOverriddenExample() {
    return {
      title: '车辆保养服务提醒',
      remark: '点击查看详情',
      nextServiceDate: '2020年1月6日 20:00',
      serviceSubject: '更换刹车油',
      serviceAgentName: '李世民',
      serviceAgentMobile: '13800138000',
    };
  }
}
