/**
 * @file: StoreConfiguration.ts
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  CarwashType,
  CustomerType,
  ImageAnnotationScene,
  OldPartDisposalType,
  OrderReservationType,
  OrderReturnType,
  OrgUserRoleType,
} from 'model/enum';

import { OrderTag } from './OrderTag';

export const kStoreConfigVersion = '1.0';

export type AppLaunchBrandingSourceType =
  | 'agent'
  | 'org'
  | 'default'
  | 'oem-provider';

export enum OrderCommentedNotificationTarget {
  ServiceAgent = 'service_agent',
  Manager = 'manager',
}

export type CommentRemindConfig = {
  delayInHours?: number;
  intervalInHours?: number;
  maxRemind?: number;
};

export type StoreStatsSummaryReportConfig = {
  dailyReport?: boolean;
  weeklyReport?: boolean;
  monthlyReport?: boolean;
  quarterlyReport?: boolean;
  reportSendTime?: string;
  emails?: string[];
  mobiles?: string[];
};

export type ExtendedPropertyType =
  | 'select'
  | 'text'
  | 'switch'
  | 'datetime'
  | 'assets';

export type ExtendedPropertySupportedAssetType = 'image' | 'video';

export type ExtendedPropertyVisibility = 'private' | 'public';

export type OrderFieldVisibility =
  | 'default' // use app specific default behavior
  | 'always' // always display the field
  | 'auto' // only display the field if value exists
  | 'none'; // don't display this field in order summary

export type ExtendedPropertySelectRenderType =
  | 'checkbox'
  | 'select'
  | 'actionsheet'
  | 'auto';

export type ExtendedOrderPropertyOption = {
  id: string;
  value?: string;
  defaultValue: string;
  label: string;
  description?: string;
  isDefaultSelected?: boolean;
  color?: string;
};

export type ExtendedPropertyDefAttachment = {
  id: string;
  type: 'qrcode' | 'image' | 'video';
  content: string;
  title?: string;
  visibility?: ExtendedPropertyVisibility;
};

export type ExtendedPropertyDef = {
  id: string;
  type: ExtendedPropertyType;
  group?: string;
  name: string;
  prop?: string;
  description?: string;
  placeholder?: string;
  visibility?: ExtendedPropertyVisibility;
  required?: boolean;
  autoFill?: boolean;
  includeInOrderSummary?: OrderFieldVisibility;
  attachments?: ExtendedPropertyDefAttachment[];
  color?: string;
  updatableBy?: OrgUserRoleType[];
} & (
  | {
      type: 'select';
      options: ExtendedOrderPropertyOption[];
      multi?: boolean;
      renderType?: ExtendedPropertySelectRenderType;
      remark?: boolean;
    }
  | {
      type: 'text';
      multiline?: boolean;
      maxLength?: number;
      minLength?: number;
    }
  | {
      type: 'switch';
      remark?: boolean;
      yesValue?: string;
      noValue?: string;
      defaultOn?: boolean;
      yesLabel?: string;
      noLabel?: string;
      yesColor?: string;
      noColor?: string;
    }
  | {
      type: 'datetime';
      dateOnly?: boolean;
      displayFormat?: string;
      remark?: boolean;
    }
  | {
      type: 'assets';
      limit?: number;
      supportedFormats?: ExtendedPropertySupportedAssetType[];
      remark?: boolean;
    }
);

export type AppLaunchConfig = {
  animationDisabled?: boolean;
  branding?: AppLaunchBrandingSourceType;
};

export type ServiceAgreementConfig = {
  type: 'markdown' | 'html';
  content: string;
};

export type OrderFieldConfig<
  T = any,
  E extends Record<string, any> = Record<string, any>,
> = {
  required?: boolean;
  hidden?: boolean;
  defaultValue?: T;
  group?: string;
  autoFill?: boolean;
  includeInOrderSummary?: OrderFieldVisibility;
  updatableBy?: OrgUserRoleType[];
} & E;

export type OrderFieldsConfig = {
  licensePlateNo?: OrderFieldConfig<string>;
  vin?: OrderFieldConfig<string>;
  vehicleInfo?: OrderFieldConfig<never>;
  serviceAgent?: OrderFieldConfig<never>;
  tags?: OrderFieldConfig<string>;
  mileage?: OrderFieldConfig<number>;
  dashboardImage?: OrderFieldConfig<string, { watermark?: boolean }>;
  facadeInspection?: OrderFieldConfig;
  serviceSubjects?: OrderFieldConfig;
  carwashType?: OrderFieldConfig<CarwashType>;
  returnType?: OrderFieldConfig<OrderReturnType>;
  oldPartDisposalType?: OrderFieldConfig<OldPartDisposalType>;
  estimatedFinishTime?: OrderFieldConfig<string>;
  reservationType?: OrderFieldConfig<OrderReservationType>;
  insuranceExpiryDate?: OrderFieldConfig<string>;
  customerType?: OrderFieldConfig<CustomerType>;
  customerName?: OrderFieldConfig<string>;
  customerMobile?: OrderFieldConfig<string, { dialRoles?: OrgUserRoleType[] }>;
  remark?: OrderFieldConfig<string>;
  extendedProperties?: OrderFieldConfig<never>;
  customerSign?: OrderFieldConfig;
};

export type OrderFlowConfig = {
  groups?: string[];
  skipSign?: boolean;
  fields?: OrderFieldsConfig;
};

export type OrderSummaryFieldConfig = {
  visibility?: OrderFieldVisibility;
};

export type OrderSummaryFieldsConfig = {
  vehicleFuelType?: OrderSummaryFieldConfig;
  storeName?: OrderSummaryFieldConfig;
  customerSigned?: OrderSummaryFieldConfig;
};

export type OrderSummaryConfig = {
  defaultExtendedPropsVisibility?: OrderFieldVisibility;
  fields?: OrderSummaryFieldsConfig;
};

export type AppBehaviorConfig = {
  autoAnnotationTagForPreInspectionDisabled?: boolean;
  orderFlow?: OrderFlowConfig;
  orderSummary?: OrderSummaryConfig;
  orderTagsForNav?: string[];
  imageAnnotationDisabledForScenes?: ImageAnnotationScene[];
};

export type ReportImageResizeMode = 'contain' | 'cover';
export type ReportDefectiveLevelSortOrder = 'asc' | 'desc';

export type StoreConfiguration = {
  version: string;
  restrictedRolesForPreDeliveryCheck?: OrgUserRoleType[] | null;
  restrictedRolesForDeliveryCheck?: OrgUserRoleType[] | null;
  restrictedRolesForPreSamplingDeliveryCheck?: OrgUserRoleType[] | null;
  restrictedRolesForSamplingDeliveryCheck?: OrgUserRoleType[] | null;
  restrictedRolesForCancelOrder?: OrgUserRoleType[] | null;
  restrictedRolesForCreateOrder?: OrgUserRoleType[] | null;
  restrictedRolesForUpdateOrder?: OrgUserRoleType[] | null;
  restrictedRolesForFinishOrder?: OrgUserRoleType[] | null;
  restrictedRolesForRollbackOrder?: OrgUserRoleType[] | null;
  rolesForRollbackOrderWithoutRestrictions?: OrgUserRoleType[] | null;
  requireReasonForUpdateOrderEstimatedFinishTime?: boolean;
  predefinedUpdateOrderEstimatedFinishTimeReasons?: string[];
  checkOrderTimeoutBeforeFinish?: boolean;
  predefinedOrderTimeoutReasons?: string[];
  isDeliveryCheckOptional?: boolean;
  isChildOrderDeliveryCheckOptional?: boolean;
  requireDeliveryCheckUnacceptedReason?: boolean;
  predefinedDeliveryCheckUnacceptedReasons?: string[];
  requireSamplingCheckBeforeFinish?: boolean;
  requireSamplingCheckForChildOrderBeforeFinish?: boolean;
  allowSamplingCheckAfterFinish?: boolean;
  noReplyOnWxExternalSubscribe?: boolean | null;
  includeCustomerInfoInExportedOrderDetailData?: boolean;
  orderCommentedNotificationTargetsExcluded?:
    | OrderCommentedNotificationTarget[]
    | null;
  orderTags?: OrderTag[] | null;
  commentRemind?: CommentRemindConfig | null;
  maxElapseTimeInHoursBeforeCanRollback?: number | null;
  storeStatsSummaryReport?: StoreStatsSummaryReportConfig | null;
  serviceAgreement?: ServiceAgreementConfig | null;
  extendedOrderProperties?: ExtendedPropertyDef[] | null;
  appLaunch?: AppLaunchConfig | null;
  app?: AppBehaviorConfig | null;
  printTermsOfServiceInNewPage?: boolean;
  productReferralServiceAgentDistributionRatio?: number;
  productAdoptionServiceAgentDistributionRatio?: number;
  disableStoreQrCodeScanEventHandler?: boolean;
  customerReportImageResizeMode?: ReportImageResizeMode;
  customerReportDefectiveLevelSortOrder?: ReportDefectiveLevelSortOrder;
};

export const makeDefaultStoreConfiguration = (): StoreConfiguration => {
  return { version: kStoreConfigVersion };
};

export const deserializeStoreConfiguration = (s: string) => {
  try {
    if (s) {
      return JSON.parse(s) as StoreConfiguration;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};
