/**
 * @file: TemplateMessageSceneTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, TemplateMessageSceneType } from 'model';

export const TemplateMessageSceneTypeOptions: Array<
  Option<TemplateMessageSceneType>
> = [
  {
    value: TemplateMessageSceneType.ServiceStarted,
    label: '@string/template_message_scene_type.service_started',
  },
  {
    value: TemplateMessageSceneType.PreInspectionFinished,
    label: '@string/template_message_scene_type.pre_inspection_finished',
  },
  {
    value: TemplateMessageSceneType.InspectionFinished,
    label: '@string/template_message_scene_type.inspection_finished',
  },
  {
    value: TemplateMessageSceneType.ConstructionFinished,
    label: '@string/template_message_scene_type.construction_finished',
  },
  {
    value: TemplateMessageSceneType.DeliveryCheckFinished,
    label: '@string/template_message_scene_type.delivery_check_finished',
  },
  {
    value: TemplateMessageSceneType.ServiceUpdated,
    label: '@string/template_message_scene_type.service_updated',
  },
  {
    value: TemplateMessageSceneType.ServiceFinished,
    label: '@string/template_message_scene_type.service_finished',
  },
  {
    value: TemplateMessageSceneType.ServiceCommentRemind,
    label: '@string/template_message_scene_type.service_comment_remind',
  },
  {
    value: TemplateMessageSceneType.VehicleBindVerify,
    label: '@string/template_message_scene_type.vehicle_bind_verify',
  },
  {
    value: TemplateMessageSceneType.StoreStatsRemind,
    label: '@string/template_message_scene_type.store_stats_remind',
  },
  {
    value: TemplateMessageSceneType.CustomerPostedComment,
    label: '@string/template_message_scene_type.customer_posted_comment',
  },
  {
    value: TemplateMessageSceneType.MaintenancePlanRemind,
    label: '@string/template_message_scene_type.maintenance_plan_remind',
  },
  {
    value: TemplateMessageSceneType.CustomerMaintenancePlanRemind,
    label:
      '@string/template_message_scene_type.customer_maintenance_plan_remind',
  },
];

export const TemplateMessageSceneTypeOptionsWithDefault: Array<
  Option<TemplateMessageSceneType | null>
> = [
  { value: null, label: 'template_message_scene_type.__default__' },
  {
    value: TemplateMessageSceneType.ServiceStarted,
    label: 'template_message_scene_type.service_started',
  },
  {
    value: TemplateMessageSceneType.PreInspectionFinished,
    label: 'template_message_scene_type.pre_inspection_finished',
  },
  {
    value: TemplateMessageSceneType.InspectionFinished,
    label: 'template_message_scene_type.inspection_finished',
  },
  {
    value: TemplateMessageSceneType.ConstructionFinished,
    label: 'template_message_scene_type.construction_finished',
  },
  {
    value: TemplateMessageSceneType.DeliveryCheckFinished,
    label: 'template_message_scene_type.delivery_check_finished',
  },
  {
    value: TemplateMessageSceneType.ServiceUpdated,
    label: 'template_message_scene_type.service_updated',
  },
  {
    value: TemplateMessageSceneType.ServiceFinished,
    label: 'template_message_scene_type.service_finished',
  },
  {
    value: TemplateMessageSceneType.ServiceCommentRemind,
    label: 'template_message_scene_type.service_comment_remind',
  },
  {
    value: TemplateMessageSceneType.VehicleBindVerify,
    label: 'template_message_scene_type.vehicle_bind_verify',
  },
  {
    value: TemplateMessageSceneType.StoreStatsRemind,
    label: 'template_message_scene_type.store_stats_remind',
  },
  {
    value: TemplateMessageSceneType.CustomerPostedComment,
    label: 'template_message_scene_type.customer_posted_comment',
  },
  {
    value: TemplateMessageSceneType.MaintenancePlanRemind,
    label: 'template_message_scene_type.maintenance_plan_remind',
  },
  {
    value: TemplateMessageSceneType.CustomerMaintenancePlanRemind,
    label: 'template_message_scene_type.customer_maintenance_plan_remind',
  },
];
