/**
 * @file: ImageAnnotationScene.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ImageAnnotationScene {
  Dashboard = 'dashboard',
  PreInspection = 'pre_inspection',
  Inspection = 'inspection',
  Construction = 'construction',
  CompletionDeliveryCheck = 'completion_check',
  SamplingDeliveryCheck = 'sampling_check',
  ReworkRecord = 'rework_record',
}

export const ImageAnnotationSceneValueSet = new Set([
  ImageAnnotationScene.Dashboard,
  ImageAnnotationScene.PreInspection,
  ImageAnnotationScene.Inspection,
  ImageAnnotationScene.Construction,
  ImageAnnotationScene.CompletionDeliveryCheck,
  ImageAnnotationScene.SamplingDeliveryCheck,
  ImageAnnotationScene.ReworkRecord,
]);
