/**
 * @file: ImageAnnotationSceneOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ImageAnnotationScene, Option } from 'model';

export const ImageAnnotationSceneOptions: Array<Option<ImageAnnotationScene>> =
  [
    {
      value: ImageAnnotationScene.Dashboard,
      label: '@string/image_annotation_scene.dashboard',
    },
    {
      value: ImageAnnotationScene.PreInspection,
      label: '@string/image_annotation_scene.pre_inspection',
    },
    {
      value: ImageAnnotationScene.Inspection,
      label: '@string/image_annotation_scene.inspection',
    },
    {
      value: ImageAnnotationScene.Construction,
      label: '@string/image_annotation_scene.construction',
    },
    {
      value: ImageAnnotationScene.CompletionDeliveryCheck,
      label: '@string/image_annotation_scene.completion_check',
    },
    {
      value: ImageAnnotationScene.SamplingDeliveryCheck,
      label: '@string/image_annotation_scene.sampling_check',
    },
    {
      value: ImageAnnotationScene.ReworkRecord,
      label: '@string/image_annotation_scene.rework_record',
    },
  ];

export const ImageAnnotationSceneOptionsWithDefault: Array<
  Option<ImageAnnotationScene | null>
> = [
  { value: null, label: 'image_annotation_scene.__default__' },
  {
    value: ImageAnnotationScene.Dashboard,
    label: 'image_annotation_scene.dashboard',
  },
  {
    value: ImageAnnotationScene.PreInspection,
    label: 'image_annotation_scene.pre_inspection',
  },
  {
    value: ImageAnnotationScene.Inspection,
    label: 'image_annotation_scene.inspection',
  },
  {
    value: ImageAnnotationScene.Construction,
    label: 'image_annotation_scene.construction',
  },
  {
    value: ImageAnnotationScene.CompletionDeliveryCheck,
    label: 'image_annotation_scene.completion_check',
  },
  {
    value: ImageAnnotationScene.SamplingDeliveryCheck,
    label: 'image_annotation_scene.sampling_check',
  },
  {
    value: ImageAnnotationScene.ReworkRecord,
    label: 'image_annotation_scene.rework_record',
  },
];
